import throttle from "lodash.throttle";
import * as React from "react";
import styled from "styled-components";
import { useChangingValue } from "../core/hooks";
import { Timer } from "../core/timer";
import { ServicesContext } from "../services/servicesContext";
import { clipTime } from "../utils/time";
import { Button } from "./button";
import { CloseIconRound } from "./closeIcon";

interface CountdownProps {
	className?: string;
	onStop?: () => void;
	onStart?: () => void;
}

const timeReducer = (time: number, modifier: { delta: number } | { input: number } | { newTime: number }) => {
	if ("delta" in modifier) {
		return Math.min(3600, Math.max(0, time + modifier.delta));
	}
	if ("newTime" in modifier) {
		return modifier.newTime;
	}
	const v = modifier.input;
	if (time > 60 * 10) {
		return time;
	}
	const { minutes, seconds } = clipTime(time);
	const text = `0000${minutes}${minutes && seconds < 10 ? 0 : ""}${seconds}${v}`.slice(-4);

	const [dm, m, ds, s] = text;

	return +dm * 60 * 10 + +m * 60 + +ds * 10 + +s;
};

type RunningState = "running" | "paused" | "stopped";

export const Countdown: React.FC<CountdownProps> = ({ className, onStart, onStop }) => {
	const { premadeTimersService } = React.useContext(ServicesContext);

	const [initialTime, setInitialTime] = React.useState(0);
	const [time, addToTime] = React.useReducer(timeReducer, 0);
	const [runningState, setRunningState] = React.useState<RunningState>("stopped");
	const premadeTimers = useChangingValue(
		premadeTimersService.premadeTimers,
		premadeTimersService.onPremadeTimersChange,
		[premadeTimersService]
	);
	const { minutes, seconds, milliseconds } = clipTime(time);
	const timer = React.useMemo(() => new Timer(dt => addToTime({ delta: -dt / 1000 })), []);

	const audio = React.useMemo(() => new Audio(require("../../assets/alarm.mp3")), []);

	React.useEffect(() => {
		if (runningState === "running") {
			timer.start();
			onStart && onStart();
		} else {
			timer.stop();
			onStop && onStop();
		}
		return () => timer.stop();
	}, [runningState]);

	React.useEffect(() => {
		if (time <= 0) {
			if (runningState === "running") {
				audio.currentTime = 0;
				audio.play();
				setTimeout(() => {
					addToTime({ newTime: initialTime });
				}, 1000);
			}
			setRunningState("stopped");
		}
	}, [time, runningState]);

	React.useEffect(() => {
		const addTimeInputOrValidate = (e: KeyboardEvent) => {
			audio.pause();
			if (e.key === "Enter") {
				if (runningState === "running") {
					setRunningState("paused");
				} else {
					start();
				}
				return;
			}
			if (runningState === "stopped") {
				const v = +e.key;
				if (!isNaN(v)) {
					addToTime({ input: v });
				}
			}
		};

		const stopAudio = () => {
			audio.pause();
		};

		document.addEventListener("keypress", addTimeInputOrValidate);
		document.addEventListener("click", stopAudio);
		document.addEventListener("wheel", stopAudio);
		document.addEventListener("touchstart", stopAudio);
		return () => {
			document.removeEventListener("click", stopAudio);
			document.removeEventListener("keypress", addTimeInputOrValidate);
			document.removeEventListener("wheel", stopAudio);
			document.removeEventListener("touchstart", stopAudio);
		};
	}, []);

	const start = () => {
		if (!time) {
			return;
		}
		setRunningState("running");
		if (!premadeTimers.includes(time)) {
			premadeTimersService.addTimer(time);
		}
		setInitialTime(time);
	};

	return (
		<Container className={className}>
			<PremadeTimers>
				{premadeTimers.map(t => {
					const { minutes: pMinutes, seconds: pSeconds } = clipTime(t);
					return (
						<PremadeTimerWrapper>
							<PremadeTimer
								onClick={() => {
									setRunningState("stopped");
									addToTime({ newTime: t });
								}}
							>
								{pMinutes < 10 ? 0 : ""}
								{pMinutes}:{pSeconds < 10 ? 0 : ""}
								{pSeconds}
							</PremadeTimer>
							<CloseWrapper
								id={`${t}`}
								onClick={() => {
									premadeTimersService.removeTimer(t);
								}}
							/>
						</PremadeTimerWrapper>
					);
				})}
			</PremadeTimers>
			<Wrapper>
				<TimeDisplay
					time={minutes}
					onIncrement={() => addToTime({ delta: 60 })}
					onDecrement={() => addToTime({ delta: -60 })}
				/>
				<div>
					<Point />
					<Point />
				</div>
				<TimeDisplay
					time={seconds}
					onIncrement={() => addToTime({ delta: 1 })}
					onDecrement={() => addToTime({ delta: -1 })}
				/>
			</Wrapper>
			<Buttons>
				{runningState === "stopped" ? (
					<Start
						color="blue"
						onClick={() => {
							start();
						}}
					>
						Démarrer
					</Start>
				) : (
					<>
						{runningState === "running" ? (
							<Pause color="white" onClick={() => setRunningState("paused")}>
								Pause
							</Pause>
						) : (
							<Pause color="green" onClick={() => setRunningState("running")}>
								Reprendre
							</Pause>
						)}

						<Stop
							color="red"
							onClick={() => {
								setRunningState("stopped");
								addToTime({ delta: -time });
							}}
						>
							Arrêter
						</Stop>
					</>
				)}
			</Buttons>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white;
	height: 490px;
	@media (max-height: 700px) {
		height: 390px;
	}

	user-select: none;
`;

const PremadeTimers = styled.div`
	padding: 40px 60px;
	@media (max-width: 500px) {
		padding: 20px 30px;
		justify-items: center;
		grid-column-gap: initial;
		grid-template-columns: repeat(auto-fill, 50%);
	}
	display: grid;
	box-sizing: border-box;
	grid-column-gap: 30px;
	grid-template-columns: repeat(auto-fill, 60px);
	grid-row-gap: 10px;
	width: 100%;
`;

const PremadeTimerWrapper = styled.div`
	position: relative;
`;

const PremadeTimer = styled.div`
	cursor: pointer;
	width: 60px;
	height: 35px;
	font-size: 16px;
	background-color: #efefef;
	color: #838383;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	&:hover  {
		background-color: #0000ff;
		color: white;
		box-shadow: 5px 8px 20px 0 rgba(0, 0, 255, 0.4);
	}
	transition: background-color 0.2s linear, color 0.2s linear, box-shadow 0.2s linear;
`;

const CloseWrapper = styled(CloseIconRound)`
	position: absolute;
	cursor: pointer;
	transform: translate(50%, -50%);
	right: 0;
	top: 0;
	width: 20px;
	height: 20px;

	display: none;
	${PremadeTimerWrapper}:hover & {
		display: block;
	}

	transition: transform ease-out 0.2s;
	&:hover {
		transform: translate(50%, -50%) scale(1.2);
	}
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 30px;
`;

const Point = styled.div`
	background-color: #0000ff;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	margin: 14px 25px;
`;

interface TimeDisplayProps {
	time: number;
	onIncrement: () => void;
	onDecrement: () => void;
}

let deltaY = 0;
let lastTouchY = 0;

const TimeDisplay: React.FC<TimeDisplayProps> = ({ time, onIncrement, onDecrement }) => {
	const increment = React.useMemo(() => throttle(onIncrement, 20), []);
	const decrement = React.useMemo(() => throttle(onDecrement, 20), []);

	return (
		<TimeDisplayWrapper
			onWheel={e => {
				deltaY += e.deltaY;
				if (Math.abs(deltaY) < 3) {
					return;
				}
				deltaY < 0 ? increment() : decrement();
				deltaY = 0;
			}}
			onTouchStart={e => (lastTouchY = e.touches[0].clientY)}
			onTouchMove={e => {
				const y = e.touches[0].clientY;
				deltaY += y - lastTouchY;
				lastTouchY = y;
				if (Math.abs(deltaY) < 3) {
					return;
				}
				deltaY < 0 ? increment() : decrement();
				deltaY = 0;
			}}
			onTouchEnd={e => (lastTouchY = 0)}
		>
			<Time>
				{time < 10 ? 0 : ""}
				{time}
			</Time>
		</TimeDisplayWrapper>
	);
};

const TimeDisplayWrapper = styled.div`
	width: 150px;
	@media (max-width: 500px) {
		width: 80px;
	}
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const Time = styled.div`
	color: blue;
	font-weight: 700;
	font-size: 150px;

	@media (max-width: 500px) {
		font-size: 80px;
	}
	line-height: 150px;
`;

const Buttons = styled.div`
	display: flex;
`;

const Start = styled(Button)``;
const Pause = styled(Button)`
	margin-right: 65px;
	@media (max-width: 500px) {
		margin-right: 20px;
	}
	@keyframes toleft {
		from {
			opacity: 0.5;
			transform: translateX(102.5px);
		}
		to {
			opacity: 1;
			transform: translateX(0);
		}
	}
	animation: toleft 500ms;
`;
const Stop = styled(Button)`
	@keyframes toRight {
		from {
			opacity: 0.5;
			transform: translateX(-37.5px);
		}
		to {
			opacity: 1;
			transform: translateX(0);
		}
	}
	animation: toRight 500ms;
`;

import * as React from "react";

export const DiscIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		width="371"
		height="372"
		viewBox="0 0 371 372"
		{...props}
	>
		<defs>
			<linearGradient id="disc-gradient" x1="4.623%" x2="67.445%" y1="21.235%" y2="96.012%">
				<stop offset="0%" stopColor="#0025FF" />
				<stop offset="100%" stopColor="#000CFF" />
			</linearGradient>
			<circle id="circle" cx="1255" cy="194" r="134" />
			<filter id="disc-filter" width="179.9%" height="179.9%" x="-39.9%" y="-20.5%" filterUnits="objectBoundingBox">
				<feOffset dy="52" in="SourceAlpha" result="shadowOffsetOuter1" />
				<feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="27" />
				<feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
			</filter>
		</defs>
		<g fill="none" fillRule="evenodd" transform="translate(-1069 -60)">
			<use fill="#000" filter="url(#disc-filter)" xlinkHref="#circle" />
			<use fill="url(#disc-gradient)" xlinkHref="#circle" />
		</g>
	</svg>
);

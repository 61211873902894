import * as React from "react";
import * as ReactDOM from "react-dom";
import { CountdownScreen } from "./countdownScreen";
import { PremadeTimersService } from "./services/premadeTimersService";
import { ServicesContext } from "./services/servicesContext";

const services = {
	premadeTimersService: new PremadeTimersService(),
};

ReactDOM.render(
	<ServicesContext.Provider value={services}>
		<CountdownScreen />
	</ServicesContext.Provider>,
	document.getElementById("main")
);

import * as React from "react";

export const ArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="445" height="433" viewBox="0 0 445 533" {...props}>
		<defs>
			<filter id="arrow-filter" width="193.5%" height="193.8%" x="-46.8%" y="-46.9%" filterUnits="objectBoundingBox">
				<feOffset dy="52" in="SourceAlpha" result="shadowOffsetOuter1" />
				<feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="27" />
				<feColorMatrix
					in="shadowBlurOuter1"
					result="shadowMatrixOuter1"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feMerge>
					<feMergeNode in="shadowMatrixOuter1" />
					<feMergeNode in="SourceGraphic" />
				</feMerge>
			</filter>
			<linearGradient id="arrow-gradient" x1="4.623%" x2="67.445%" y1="21.467%" y2="95.64%">
				<stop offset="0%" stopColor="#0025FF" />
				<stop offset="100%" stopColor="#000CFF" />
			</linearGradient>
		</defs>
		<path
			fill="url(#arrow-gradient)"
			fillRule="evenodd"
			d="M43.559 43.918l-.102 88.521 258.212-.403-.226 257.28 88.953-.1.48-345.912z"
			filter="url(#arrow-filter)"
			transform="rotate(-163 236.028 213.685)"
		/>
	</svg>
);

const SIXTY_FPS = 1000 / 60;

export class Timer {
	private _timer = 0;
	private _time = 0;
	private _isRunning = false;

	constructor(readonly tick: (dt: number) => void) {}

	start = () => {
		if (!this._isRunning) {
			this._time = Date.now();
			this._timer = setInterval(this.handler, SIXTY_FPS);
			this._isRunning = true;
		} else {
			console.warn("timer already running");
		}
	};

	handler = () => {
		const time = Date.now();
		const dt = time - this._time;
		this._time = time;
		this.tick(dt);
	};

	stop = () => {
		clearInterval(this._timer);
		this._isRunning = false;
	};

	reset = () => {
		this.stop();
		this.start();
	};
}

import styled from "styled-components";

export const Button = styled.button<{ color: "white" | "red" | "blue" | "green" }>`
	width: 150px;
	@media (max-width: 500px) {
		width: 120px;
	}
	height: 50px;
	color: white;
	border: ${({ color }) => (color === "white" ? "1px #080808 solid" : "none")};
	box-shadow: none;
	font-weight: 500;
	font-size: 16px;
	cursor: pointer;
	outline: none;
	background-color: ${({ color }) => backgrounds[color]};
	color: ${({ color }) => colors[color]};
`;

const backgrounds = {
	white: "white",
	red: "#d00026",
	blue: "0000ff",
	green: "12dd2f",
};

const colors = {
	white: "#080808",
	red: "white",
	blue: "white",
	green: "white",
};

import * as React from "react";
import styled from "styled-components";
import { ArrowIcon } from "./components/arrowIcon";
import { Countdown } from "./components/countdown";
import { DiscIcon } from "./components/discIcon";
import { Logo } from "./components/logo";

export const CountdownScreen = () => {
	const [started, setStarted] = React.useState(false);

	return (
		<Page>
			<FirstPart>
				<AnimatedLogo>
					<Disc countdownStarted={started} />
					<Arrow countdownStarted={started} />
				</AnimatedLogo>
				<CounterWrapper>
					<BeToLogo />
					<Counter onStop={() => setStarted(false)} onStart={() => setStarted(true)} />
				</CounterWrapper>
			</FirstPart>
		</Page>
	);
};

const Page = styled.div`
	flex: 1;
	background-color: #f6f6f6;
`;

const FirstPart = styled.div`
	height: 500px;
	@media (max-height: 700px) {
		height: 400px;
	}
	background-color: #0000ff;
	display: flex;
	align-items: center;
	flex-direction: column;
`;

const CounterWrapper = styled.div`
	box-sizing: border-box;
	max-width: 1135px;
	padding: 0 40px;
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const BeToLogo = styled(Logo)`
	z-index: 1;
	margin-top: 100px;
	@media (max-height: 700px) {
		margin-top: 50px;
	}
	flex-shrink: 0;
`;

const Counter = styled(Countdown)`
	z-index: 1;
	transform: translateY(10vh);
`;

const AnimatedLogo = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	transform: translate(20vw, 100px);
	@media (max-width: 1000px) {
		transform: translate(20vw, 100px) scale(0.9);
	}
	@media (max-width: 900px) {
		transform: translate(20vw, 100px) scale(0.8);
	}
	@media (max-width: 800px) {
		transform: translate(20vw, 100px) scale(0.7);
	}
	@media (max-width: 700px) {
		transform: translate(20vw, 100px) scale(0.6);
	}
	@media (max-width: 600px) {
		transform: translate(20vw, 100px) scale(0.5);
	}
	@media (max-width: 500px) {
		transform: translate(20vw, 100px) scale(0.4);
	}
	@media (max-width: 500px) and (min-height: 700px) {
		transform: translate(20vw, 160px) scale(0.4);
	}
`;

const Disc = styled(DiscIcon)<{ countdownStarted: boolean }>`
	position: absolute;
	transform: translate(${({ countdownStarted }) => (countdownStarted ? "-200px, 0" : "0,0")});
	transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	overflow: visible;
`;

const Arrow = styled(ArrowIcon)<{ countdownStarted: boolean }>`
	position: absolute;
	transform: translate(${({ countdownStarted }) => (countdownStarted ? "80px, -200px" : "-180px, -150px")})
		rotate(${({ countdownStarted }) => (countdownStarted ? "180deg" : "60deg")});
	transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	overflow: visible;
`;

import * as React from "react";

export const CloseIconRound: React.FC<React.SVGProps<SVGSVGElement>> = ({ id, ...props }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
		<defs>
			<path
				id={`cross${id}`}
				d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm1.414-10l2.536-2.536a1 1 0 1 0-1.414-1.414L10 8.586 7.464 6.05A1 1 0 0 0 6.05 7.464L8.586 10 6.05 12.536a1 1 0 0 0 1.414 1.414L10 11.414l2.536 2.536a1 1 0 0 0 1.414-1.414L11.414 10z"
			/>
		</defs>
		<g fill="none" fillRule="evenodd" transform="translate(2 2)">
			<mask id={`round${id}`} fill="#fff">
				<use xlinkHref={`#cross${id}`} />
			</mask>
			<g fill="#000" mask={`url(#round${id})`}>
				<path d="M-2-2h24v24H-2z" />
			</g>
		</g>
	</svg>
);

export function clipTime(time: number): { minutes: number; seconds: number; milliseconds: number } {
	const seconds = time % 60;
	const roundMinutes = seconds > 59 && seconds <= 60;

	return {
		minutes: roundMinutes ? Math.ceil(time / 60) : Math.floor(time / 60),
		seconds: roundMinutes ? 0 : Math.ceil(seconds),
		milliseconds: time % 1,
	};
}

import { Set } from "immutable";
import { Signal } from "micro-signals";

const TIMERS_KEY = "timers";
const defaultTimers = [60, 120, 300, 420];

export class PremadeTimersService {
	private _premadeTimers: Set<number>;
	onPremadeTimersChange = new Signal<Set<number>>();

	constructor() {
		const storedTimers = localStorage.getItem(TIMERS_KEY);
		this._premadeTimers = storedTimers ? Set(JSON.parse(storedTimers)) : Set(defaultTimers);
	}

	private saveTimers() {
		localStorage.setItem(TIMERS_KEY, JSON.stringify(this._premadeTimers));
	}

	addTimer(timer: number) {
		this._premadeTimers = this._premadeTimers.add(timer);
		this.saveTimers();
		this.onPremadeTimersChange.dispatch(this._premadeTimers);
	}

	removeTimer(timer: number) {
		this._premadeTimers = this._premadeTimers.remove(timer);
		this.saveTimers();
		this.onPremadeTimersChange.dispatch(this._premadeTimers);
	}

	get premadeTimers() {
		return this._premadeTimers;
	}
}
